import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/administration.module').then( m => m.AdministrationPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'publicity',
    loadChildren: () => import('./publicity/publicity.module').then( m => m.PublicityPageModule)
  },
  {
    path: 'store',
    loadChildren: () => import('./store/store.module').then( m => m.StorePageModule)
  },
  {
    path: 'product-position',
    loadChildren: () => import('./product-position/product-position.module').then( m => m.ProductPositionPageModule)
  },
  {
    path: 'category-position',
    loadChildren: () => import('./category-position/category-position.module').then( m => m.CategoryPositionPageModule)
  },
  {
    path: 'documentation',
    loadChildren: () => import('./documentation/documentation.module').then( m => m.DocumentationPageModule)
  },  {
    path: 'product-mass-import',
    loadChildren: () => import('./product-mass-import/product-mass-import.module').then( m => m.ProductMassImportPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
